import Header from '@/src/components/Header/Header';
import Footer from '@/src/components/common/Footer';

type propsType = {
  children: JSX.Element | JSX.Element | any;
};

export default function Global({ children }: propsType) {
  return (
    <main>
      <Header searchBarMobile searchCancel addClass />
      {children}
      <Footer />
    </main>
  );
}
