import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const rightArrowIcon = require('../../../assets/img/arrow-black.svg');

const PromiseFloorV2 = ({ cards }) => {
  const promiseParams = {
    centeredSlides: false,
    breakpoints: {
      1025: {
        slidesPerView: cards?.length || 4,
        spaceBetween: 0,
      },
      769: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 0,
      },

      320: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
    },
  };

  return (
    <>
      {cards?.length > 0 && (
        <div className="py-4 mb-4 md:mb-14 bg-grey-50">
          <div className="w-full px-2 md:px-4">
            <Desktop>
              <div className="flex justify-around  px-8">
                {cards.map((card, index) => {
                  return (
                    <>
                      <div
                        className="flex self-center  py-2 justify-around  border-solid border-grey-300 last:border-none"
                        key={index}
                      >
                        <a className="flex items-center" href={card.href} target={card.openInNewTab ? '_blank' : ''}>
                          <span className="mr-1.5">
                            <img className="w-5 h-5" src={`${card.image}?format=auto`} alt={`${card.text}`} />
                          </span>
                          <span
                            className="px-1 text-center text-12 md:text-16 text-blue-400"
                            data-test-id={`homepage:homepage-section-promise-title-${card?.text
                              ?.split(' ')
                              .join('')
                              .toLowerCase()}`}
                          >
                            {card.text}
                          </span>
                          <span className="relative -right-16">
                            <span className=" mx-1 cn-265 cn-152 flex items-center border border-solid rounded-xl border-transparent bg-white">
                              <img src={rightArrowIcon} className=" min-w-[22px] min-h-[22px]" alt="Off icon" />
                            </span>
                          </span>
                        </a>
                      </div>

                      {index !== cards.length - 1 ? (
                        <div>
                          <div className="w-[1px] h-full bg-black"></div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </div>
            </Desktop>

            <Mobile>
              <Swiper breakpoints={promiseParams.breakpoints} centeredSlides={promiseParams.centeredSlides}>
                {cards.map((card, index) => (
                  <SwiperSlide
                    className="pl-2.5 py-1.5 pr-7  flex justify-around self-center border-r border-solid last:border-r-0"
                    key={index}
                  >
                    <a className="flex items-center" href={card.href} target={card.openInNewTab ? '_blank' : ''}>
                      <span>
                        <img className="max-w-[20px]" src={`${card.image}?format=auto`} alt={`${card.text}`} />
                      </span>
                      <span
                        className="px-1 text-center text-12 md:text-16 text-blue-400 w-11/12"
                        data-test-id={`homepage:homepage-section-promise-title-${card?.text
                          ?.split(' ')
                          .join('')
                          .toLowerCase()}`}
                      >
                        {card.text}
                      </span>
                      <span className="relative ">
                        <span className="px-1 mx-1 cn-265 cn-152 flex items-center border border-solid rounded-xl border-transparent bg-white">
                          <img src={rightArrowIcon} className=" min-w-[14px] min-h-[20px]" alt="Off icon" />
                        </span>
                      </span>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Mobile>
          </div>
        </div>
      )}
    </>
  );
};

export default PromiseFloorV2;
