import React, { useEffect, useState } from 'react';
import serverSideApi from '@/src/lib/dynamicYield/serverSideApi';
import ProductScrollSkeleton from '../FloorSkeleton/ProductScrollSkeleton';
import { DyProductScroll, DualBannerCard, FourBanner, MiniBanner } from '../CmsFloors/CmsFloors';

export default function DyFloor({ data, pageType }: { data: any; pageType: string }) {
  let floor;
  const [DyData, setDyData] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const fetchDyAPI = async () => {
    setLoading(true);
    const responseData = await serverSideApi(null, {
      page: {
        type: pageType,
        location: window.location.href,
        data: [data?.selectorName],
      },
      selector: {
        names: [data?.selectorName],
      },
    });
    setDyData(responseData);
    setLoading(false);
  };

  useEffect(() => {
    fetchDyAPI();
  }, []);

  switch (true) {
    case data?.floorType === 'productFloor':
      if (isLoading) {
        floor = <ProductScrollSkeleton />;
      } else {
        floor = <DyProductScroll dyData={DyData?.data} pageType={pageType} />;
      }
      break;

    case data?.floorType === 'fourBanner':
      floor = <FourBanner data={DyData?.data} toggleVideoFrame={() => {}} fourBannerCallback={() => {}} />;
      break;

    case data?.floorType === 'dualBanner':
      floor = <DualBannerCard data={DyData?.data} dualBannerCallback={() => {}} />;
      break;
    case data?.contentType === 'miniBanner':
      floor = <MiniBanner data={DyData?.data} miniBannerCallback={() => {}} floorHeading={''} />;
      break;

    default:
      floor = '';
  }

  return <div>{floor}</div>;
}
