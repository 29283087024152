import * as wcmsOptimization from '@/src/wcmsOptimization';
import React, { useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import DecaLink from '../../DecathlonTheme/DecaLink';
import { Desktop, Mobile } from '../../common/CustomMediaQuery';

const CategoryCarousel = ({ data, index }) => {
  const [isRendered, setIsRendered] = useState(false); // For optimisation
  const space = useMemo(() => {
    const input = data.links?.length || 0;
    if (input <= 4) {
      return 16;
    } else if (input <= 8) {
      return 8;
    } else if (input <= 10) {
      return 4;
    } else {
      return 2;
    }
  }, []);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <>
      {data?.links?.length > 0 && (
        <div className="py-3.5 bg-grey-100 " data-test-id={`${wcmsOptimization.floorTypes.categoryCarousel}-${index}`}>
          <Desktop>
            <div className="flex justify-center">
              {data.links.map((link) => (
                <div
                  className={`flex self-center px-${space} justify-around border-r border-solid border-grey-300 last:border-none`}
                  key={link.href}
                >
                  <DecaLink href={link.href} target={link.openInNewTab ? '_blank' : ''}>
                    <span
                      className="px-2.5 whitespace-nowrap text-14 font-bold uppercase text-grey-600"
                      data-test-id={`homepage_swiper:homepage-swiper-${
                        link?.text && link.text.split(' ').join('').toLowerCase()
                      }`}
                    >
                      {link.text}
                    </span>
                  </DecaLink>
                </div>
              ))}
            </div>
          </Desktop>
          <Mobile>
            {isRendered ? (
              <Swiper
                slidesPerView={3}
                breakpoints={{
                  640: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 5,
                  },
                  1025: {
                    slidesPerView: 8,
                  },
                }}
              >
                {data.links.map((link) => (
                  <SwiperSlide
                    className="flex self-center justify-around border-r border-solid border-grey-300 last:border-none"
                    key={link.href}
                  >
                    <DecaLink href={link.href} target={link.openInNewTab ? '_blank' : ''}>
                      <span
                        className="px-2.5 whitespace-nowrap text-14 font-bold uppercase text-grey-600"
                        data-test-id={`homepage_swiper:homepage-swiper-${
                          link?.text && link.text.split(' ').join('').toLowerCase()
                        }`}
                      >
                        {link.text}
                      </span>
                    </DecaLink>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="categoryCarouselSwiperWrapperClass overflow-hidden">
                {data.links.map((link, i) => (
                  <div
                    className="flex self-center px-4 justify-around border-r border-solid border-grey-300 last:border-none"
                    key={i}
                  >
                    <DecaLink href={link.href} target={link.openInNewTab ? '_blank' : ''}>
                      <span
                        className="px-5 xs:px-6 whitespace-nowrap text-14 font-bold uppercase text-grey-600"
                        data-test-id={`homepage_swiper:homepage-swiper-${
                          link?.text && link.text.split(' ').join('').toLowerCase()
                        }`}
                      >
                        {link.text}
                      </span>
                    </DecaLink>
                  </div>
                ))}
              </div>
            )}
          </Mobile>
        </div>
      )}
    </>
  );
};

export default CategoryCarousel;
