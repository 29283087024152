import { ISetDYContext } from './dynamicYield.types';

/**
 * DY Context Using Code
 *
 * @param {string} type - we have set only specified type in DY: https://dy.dev/docs/page-context
 * @param {string[]} data - data can be sku, slug, page: https://dy.dev/docs/page-context
 * @param {string} lng -
 * @returns {null} this function is used set the DY context, not used for returns. This function will return undefined.
 *
 */

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION';

declare global {
  interface Window {
    DY: any;
  }
}

const setDYContext = ({ type, data = '', lng }: ISetDYContext): undefined => {
  const pageContext: ISetDYContext = {
    type,
  };

  if (data) pageContext.data = data;
  if (lng) pageContext.lng = lng;

  if (!isProduction) {
    console.log(`DY ${process.env.NEXT_PUBLIC_ENVIRONMENT}: setDYContext`, pageContext);
  }

  window.DY = window.DY || {};
  window.DY.recommendationContext = pageContext;
};

export default setDYContext;
