import { getCookies } from '@/src/Utils/Cookies';
import axios from 'axios';

/**
 * DY Server Side Api Call
 *
 * @info https://dy.dev/docs/add-to-cart
 *
 * @param {string} apiUrl - url which will be used to make the api call. Two urls are there Client url and Server url which will be used based on the ctx value
 * @param {string} apiKey - api key which will be used to make the api call. Two api keys are there Client key and Server key which will be used based on the ctx value
 * @returns {object[]} this function is returns server side data from DY.
 *
 */

export default async function serverSideApi(ctx: any = {}, { page, selector, options }: any = {}) {
  const apiURL: any = ctx
    ? process.env.NEXT_PUBLIC_DYNAMICYIELD_SERVER_URL
    : process.env.NEXT_PUBLIC_DYNAMICYIELD_CLIENT_URL;
  const apiKey: any = ctx
    ? process.env.NEXT_PUBLIC_DYNAMICYIELD_SERVER_API_KEY
    : process.env.NEXT_PUBLIC_DYNAMICYIELD_CLIENT_API_KEY;
  const { _dyid, _dyid_server, _dyjsession } = ctx ? getCookies(ctx) : getCookies();
  const dyid = _dyid || _dyid_server;
  let apiResponse = {
    status: false,
    data: [],
  };

  try {
    await axios
      .post(
        apiURL,
        {
          user: {
            active_consent_accepted: false,
            dyid,
            dyid_server: dyid,
          },
          session: {
            dy: _dyjsession,
          },
          context: {
            page,
            channel: 'WEB',
          },
          selector,
          options: {
            isImplicitPageview: false,
            returnAnalyticsMetadata: false,
            isImplicitImpressionMode: true,
            isImplicitClientData: false,
            ...options,
          },
        },
        {
          headers: {
            'dy-api-key': apiKey,
          },
        },
      )
      .then((response) => {
        apiResponse = {
          status: true,
          data: response?.data?.choices[0]?.variations[0]?.payload?.data || [],
        };
      });
  } catch (error) {
    console.error('API call error:', error);
  }
  return apiResponse;
}
